@media only screen and (min-width: 768px) {
  .trn {
    background: transparent !important;
    z-index: 99;
  }

  .car {
    margin-top: -10%;
  }
}

.navbar {
  position: sticky !important;
  top: 0 !important;
  a {
    color: #fff !important;
  }
  a:hover {
    color: #e48632 !important;
  }
}

.artenix-logo {
  width: 90%;
  @media only screen and (max-width: 992px) {
    width: 50%;
  }
}

.navbar-toggler-icon {
  float: right !important;
}

.artenix-navbar {
  margin-right: 10%;
  justify-content: center !important;
}

.banner {
  background-color: #000;
}

.banner-image {
  width: 100%;
  height: auto;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
}

.banner-text {
  color: #fff;
  .art {
    color: #e48632;
  }
}

.page {
  background-image: url("../background.png");
}

.service-title {
  color: #fff;
}

.services-box {
  padding: 2%;
  background-color: #000;
  color: #fff;
  @media (max-width: 991px) {
    padding: 10%;
  }
  h5 {
    color: #e48632;
  }
}

.contact-box {
  padding: 5%;
  background-color: #000;
  color: #fff;
  @media (max-width: 991px) {
    padding: 10%;
  }
  h5 {
    color: #e48632;
  }

  .form-control {
    background-color: transparent;
    border: transparent;
    border-bottom: 1px solid #e48632;
  }
}

.submit-button {
  background-color: #e48632;
}

hr {
  width: 100%;
  height: 2px !important;
  background: #e48632;
  margin: 20px 0;
  border: none;
  box-sizing: content-box;
  overflow: visible;
}

.animation-element {
  opacity: 1;
  position: relative;
  transition: all 850ms ease;
}

.about-box {
  border: 3px solid transparent;
  background-clip: padding-box;

  h4 {
    color: #fff;
    text-align: center;
  }
  padding: 2%;
  @media (max-width: 991px) {
    padding: 10%;
  }
  background-color: #000;
  color: #fff;
}

.about-icon {
  width: 100px;
}

#social-icons {
  .navbar-nav {
    @media only screen and (max-width: 992px) {
      flex-direction: row !important;
      justify-content: space-between !important;
    }
  }
}

.carousel-indicators {
  margin-bottom: 2rem;
  display: none;
}

.orange-color {
  color: #e48632;
}

.about-page-1 {
  padding-top: 2%;
}

.about-page-2 {
  padding-top: 2%;
  padding-bottom: 20%;
}

.services-page-1 {
  padding-top: 2%;
}

.services-page-2 {
  padding: 2%;
  padding-bottom: 20%;
}

.carousel-control-prev:hover {
  color: #e48632 !important;
}

.carousel-control-next:hover {
  color: #e48632 !important;
}
